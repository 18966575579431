import React, { useContext, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Layer, Box, ThemeContext } from 'grommet';
import { LazyMotion, m } from 'framer-motion';

import useMobile from './useMobile';
import useBuybarUi from './Product/useBuybarUi';

const loadFeatures = () =>
  import('../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const AnimatedBox = m(Box);

const Buybar = ({ children, position, willDetach = true }) => {
  const theme = useContext(ThemeContext);
  const sampleCartOpen = useSelector((state) => state.cart.sampleCartOpen);
  const isMobile = useMobile();

  const { isAtTop } = useBuybarUi();

  const variants = {
    mobileBumped: {
      opacity: 0,
      pointerEvents: 'none',
      y: '100%',
      transition: { duration: 0.2 },
    },
    top: {
      y: 0,
      x: 0,
      pointerEvents: 'all',
    },
    notTop: {
      y: parseInt(theme.global.borderSize.small),
      x: 0,
      transition: { duration: 0.2 },
      pointerEvents: 'all',
    },
  };

  const buybarState =
    isMobile && sampleCartOpen ? 'mobileBumped' : isAtTop ? 'top' : 'notTop';

  return (
    <ThemeContext.Extend
      value={{
        layer: {
          zIndex: 10,
        },
      }}
    >
      <LazyMotion strict features={loadFeatures}>
        <Layer
          responsive={false}
          position={
            position
              ? position
              : isAtTop
              ? 'bottom'
              : isMobile
              ? 'bottom'
              : 'bottom-right'
          }
          plain={true}
          modal={false}
          className="buybar"
          animation={false}
          full={isMobile ? 'horizontal' : false}
          style={{
            zIndex: 1,
            pointerEvents: buybarState === 'mobileBumped' ? 'none' : 'all',
          }}
        >
          <AnimatedBox
            layout
            className="animatedBox"
            align="center"
            pad={
              isMobile
                ? 'none'
                : {
                    horizontal: willDetach
                      ? isAtTop
                        ? 'xlarge'
                        : 'none'
                      : 'none',
                    bottom: willDetach ? (isAtTop ? 'xlarge' : 'none') : 'none',
                  }
            }
            justify="center"
            gap="medium"
            id="buybar--main__inner"
            animate={buybarState}
            variants={variants}
            transition={{
              duration: 0.3,
            }}
          >
            <m.div
              className="motion-div"
              layout="position"
              style={{ width: '100%' }}
            >
              {children}
            </m.div>
          </AnimatedBox>
        </Layer>
      </LazyMotion>
    </ThemeContext.Extend>
  );
};

Buybar.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
  willDetach: PropTypes.bool,
};

export default memo(Buybar);
