import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { head, get, isEqual, toLower, find } from '../lib/nodash';
import { Box, Heading } from 'grommet';
import loadable from '@loadable/component';

import { getMetafieldTags, findMetafieldValue } from '../lib/product';
import { findImage } from '../lib/findImage';
import HeroProductDetail from './HeroProductDetail';
import Buybar from './Buybar';
import { viewPlacementDetails } from '../state/gallery/gallerySlice';
import makeSelectRelatedProducts from '../state/product/makeSelectRelatedProducts';
import { track } from '../lib/analytics';
import { isSupply } from '../lib/product';
import { PDP_GALLERY_INTERACTION } from '../lib/analytics/segmentActions';

const ProductBuyOptionsGiftCard = loadable(() =>
  import('./Product/ProductBuyOptionsGiftCard')
);
const ProductBuyOptionsMultiVariant = loadable(() =>
  import('./Product/ProductBuyOptionsMultiVariant')
);
const ProductBuyOptionsTouchUpKit = loadable(() =>
  import('./Product/ProductBuyOptionsTouchUpKit')
);
const RelatedCardComponent = loadable(() =>
  import('./Product/RelatedCardComponent')
);
const SliderReviewQuotes = loadable(() =>
  import('./Reviews/SliderReviewQuotes')
);

const ModuleSliderGallery = loadable(() => import('./ModuleSliderGallery'));
const ProductBreadcrumbHero = loadable(() =>
  import('./Product/ProductBreadcrumbHero')
);
const RelatedProducts = loadable(() => import('./Product/RelatedProducts'));
const ProductBuyOptions = loadable(() => import('./Product/ProductBuyOptions'));

const getGalleryIdentifier = (product) => {
  const alternatePDP = findMetafieldValue(
    'identifier',
    product.metafields,
    'pdp_gallery'
  );
  if (alternatePDP) {
    return alternatePDP;
  }
  switch (get('productType', product)) {
    case 'tools':
      return 'supplies';
    default:
      return 'supplies';
  }
};

const PageProductDetail = ({ product, content, collection, reviews = [] }) => {
  const dispatch = useDispatch();

  const heroImage = findImage(get('media', product), '_hero');
  const relatedHandles = getMetafieldTags(
    get('metafields', product),
    'products',
    'pdp_related'
  );

  const activePlacementId = useSelector(
    (state) => state.gallery.activePlacementId
  );
  const selectRelatedProducts = useMemo(makeSelectRelatedProducts, []);
  const relatedProducts = useSelector((state) =>
    selectRelatedProducts(state, relatedHandles)
  );

  const handleViewPlacement = (action) => {
    const placement = find({ id: action.placementId }, action.placements);
    track(PDP_GALLERY_INTERACTION, {
      product: get('title', product),
      imageUrl: get('ugc.file.url', placement),
      ugcId: get('ugc.id', placement),
      attribution: get('ugc.attribution', placement),
    });
    dispatch(viewPlacementDetails(action));
  };

  const renderBuyOptions = () => {
    switch (get('productType', product)) {
      case 'Giftcard':
        return (
          <ProductBuyOptionsGiftCard variants={get('variants', product)} />
        );
      case 'Gift Card':
        return (
          <ProductBuyOptionsGiftCard variants={get('variants', product)} />
        );
      case 'Toolskit':
        return (
          <ProductBuyOptions
            variant={head(get('variants', product))}
            maxQty={10}
          />
        );
      case 'Tools':
        if (toLower(get('title', product)).indexOf('touch up') > -1) {
          return (
            <ProductBuyOptionsTouchUpKit
              variant={head(get('variants', product))}
              maxQty={10}
            />
          );
        } else {
          return (
            <ProductBuyOptions
              variant={head(get('variants', product))}
              maxQty={10}
            />
          );
        }
      case 'Fandeck':
        return (
          <ProductBuyOptions
            variant={head(get('variants', product))}
            maxQty={10}
          />
        );
      case 'Apparel':
        if (product.totalVariants === 1) {
          return (
            <ProductBuyOptions
              variant={head(get('variants', product))}
              maxQty={5}
            />
          );
        } else {
          return (
            <ProductBuyOptionsMultiVariant
              variants={get('variants', product)}
              product={product}
              transformVariantLabel={(title) => title.split('Apparel / ')[1]}
            />
          );
        }
      default:
        return (
          <ProductBuyOptionsMultiVariant
            variants={get('variants', product)}
            product={product}
          />
        );
    }
  };

  const galleryIdentifier = useMemo(() => getGalleryIdentifier(product));
  const showRelatedProducts =
    ['Fandeck'].indexOf(product.productType) === -1 &&
    product.handle !== 'sample-binder';

  return (
    <Box style={{ position: 'relative' }}>
      <ProductBreadcrumbHero product={product} collection={collection} />
      {content ? (
        content
      ) : (
        <Box>
          <HeroProductDetail
            title={get('title', product)}
            shortDescription={get('description', product)}
            productType={get('productType', product)}
            variants={get('variants', product)}
            heroImage={{ original_src: heroImage }}
            id={get('id', product)}
            breadcrumb={false}
          />

          {showRelatedProducts && relatedProducts.length > 0 && (
            <RelatedProducts
              relatedProducts={relatedProducts}
              listItemComponent={RelatedCardComponent}
              metaContainerProps={{ align: 'start' }}
              listContainerProps={{ background: { color: 'lightGray' } }}
              border={{ side: 'horizontal', size: 'small' }}
              title="Optional Extras"
              collection={get('handle', collection)}
              altLayout={true}
              description={
                isSupply(product)
                  ? `We've got you covered with a selection of the highest quality supplies, so you never have to step into a hardware store.`
                  : undefined
              }
            />
          )}
          {reviews.length > 0 && (
            <Box>
              <Box
                border={{ side: 'horizontal', size: 'small', color: 'black' }}
                pad={'medium'}
                align="center"
              >
                <Heading>The Reviews</Heading>
              </Box>
              <Box
                pad={{ vertical: 'medium' }}
                border={{ side: 'bottom', size: 'small', color: 'black' }}
              >
                <SliderReviewQuotes reviews={reviews} />
              </Box>
            </Box>
          )}
          {galleryIdentifier &&
            !isEqual('Giftcard', get('productType', product)) && (
              <ModuleSliderGallery
                identifier={galleryIdentifier}
                activePlacementId={activePlacementId}
                onViewPlacement={handleViewPlacement}
                border={
                  showRelatedProducts
                    ? undefined
                    : { side: 'top', size: 'small' }
                }
              />
            )}
        </Box>
      )}
      <Buybar position="bottom-right">{renderBuyOptions()}</Buybar>
    </Box>
  );
};

PageProductDetail.propTypes = {
  id: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  galleryIdentifier: PropTypes.string,
  content: PropTypes.node,
  collection: PropTypes.string,
  reviews: PropTypes.array,
};

export default memo(PageProductDetail);
