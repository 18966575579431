import { useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBuybarHeight } from '../../state/ui/uiSlice';

const useBuybarUi = () => {
  const dispatch = useDispatch();
  const buybarRef = useRef(null);
  const isAtTop = useSelector((state) => state.ui.scrolledToTop);

  useLayoutEffect(() => {
    const height =
      buybarRef.current && buybarRef.current.getBoundingClientRect().height;
    if (height) {
      dispatch(setBuybarHeight(height));
    }
  }, [buybarRef.current]);

  return { isAtTop, buybarRef };
};

export default useBuybarUi;
