import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { head } from '../../lib/nodash';
import { Text, Box } from 'grommet';

import TextUnderlined from '../TextUnderlined';
import {
  getVariantPrice,
  getMinUnitLabel,
  isPaint,
  isPrimer,
  getLowestVariantPrice,
} from '../../lib/product';
import formatCurrency from '../../lib/formatCurrency';
import useMobile from '../useMobile';
import useVariantIdParam from './useVariantIdParam';

function getLabel({ activeVariant, variants, representRange, productType }) {
  if (activeVariant) {
    return activeVariant.title.replace('Paint / ', '');
  }
  return representRange
    ? `Starting at`
    : getMinUnitLabel(
        variants.map((x) => ({ ...x, product: { productType } })),
        productType
      );
}

const ProductUnitPrice = ({ variants, productType }) => {
  const activeVariantId = useVariantIdParam();

  const activeVariant = activeVariantId
    ? variants.find(({ shopifyId }) => {
        return shopifyId === `gid://shopify/ProductVariant/${activeVariantId}`;
      })
    : undefined;

  const variant = activeVariant || head(variants);
  const productIsPaint = isPaint({ productType }) && !isPrimer({ productType });
  const representRange = productIsPaint && !activeVariant;
  const isMobile = useMobile();

  const price = useMemo(
    () =>
      representRange
        ? getLowestVariantPrice(variants)
        : getVariantPrice(variant),
    [variants, variant, activeVariant]
  );

  const label = getLabel({
    activeVariant,
    variants,
    representRange,
    productType,
  });

  const size = isMobile ? 'medium' : 'xlarge';

  return (
    <Text size={size}>
      {label}{' '}
      <TextUnderlined size={size}>
        {representRange ? formatCurrency(price) : price}
      </TextUnderlined>
      <Box style={{ display: 'none' }}>
        <ul>
          {variants
            .sort((a, b) =>
              parseInt(getVariantPrice(a).replace('$', '')) <
              parseInt(getVariantPrice(b).replace('$', ''))
                ? -1
                : 1
            )
            .map((v) => (
              <li key={v.id}>
                <Text size="small">
                  {variant.title} &mdash; {getVariantPrice(v)}
                </Text>
              </li>
            ))}
        </ul>
      </Box>
    </Text>
  );
};

ProductUnitPrice.propTypes = {
  variants: PropTypes.array,
  productType: PropTypes.string,
};

export default memo(ProductUnitPrice);
