import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from '../lib/nodash';
import { Box, Heading, Paragraph, ThemeContext, Stack } from 'grommet';
import useMobile from './useMobile';
import useAdjustedHeadingSize from './useAdjustedHeadingSize';

import ProductUnitPrice from './Product/ProductUnitPrice';
import ImgixImage from './ImgixImage';
import { isPaint as isProductPaint } from '../lib/product';
import useMenuOffset from './useMenuOffset';

const HeroProductDetail = ({
  title,
  shortDescription,
  productType,
  variants,
  dark,
  backgroundColor,
  heroImage,
  pixelSwap = true,
  ...rest
}) => {
  const { buybarHeight, announcementBarSize, navHeight } = useMenuOffset();

  const isMobile = useMobile();
  const isPaint = isProductPaint({ productType });
  const theme = useContext(ThemeContext);
  const heroSource =
    get('original_src', heroImage) || get('originalSrc', heroImage);
  const adjustedFontStyle = useAdjustedHeadingSize(2);

  return (
    <Box
      height={`calc(100vh - ${navHeight}px - ${announcementBarSize}px - ${
        isMobile ? buybarHeight : 0
      }px)`}
      {...rest}
    >
      <Box
        background={{
          color: backgroundColor
            ? backgroundColor
            : isPaint
              ? dark
                ? 'brand'
                : 'white'
              : 'light-2',
          dark,
        }}
        fill
        align="center"
        justify={isPaint ? 'center' : 'end'}
      >
        <Stack interactiveChild="last" fill align="center">
          <Box fill width={{ max: '100vw' }}>
            <ImgixImage
              src={heroSource}
              fit={isPaint ? 'cover' : 'contain'}
              height="100%"
              width="100%"
              lazy={false}
              options={{ q: 80, w: 500, auto: ['compress', 'format'] }}
              sizes="100vw"
              pixelSwap={pixelSwap}
              srcSetOptions={{
                q: 85,
                auto: ['compress', 'format'],
              }}
              alt={title}
              a11yTitle={
                isPaint
                  ? `Photo of ${title} painted on a wall`
                  : `Photo of ${title}`
              }
            />
          </Box>
          {isPaint ? (
            <Box
              align="center"
              justify={'center'}
              fill
              className="inner-paint"
              width={{ max: '100vw' }}
              pad={{
                bottom: `${buybarHeight}px`,
                horizontal: 'medium',
                top: 'medium',
              }}
            >
              <ProductUnitPrice productType={productType} variants={variants} />

              <Heading
                level={1}
                margin={{ vertical: 'medium' }}
                textAlign="center"
              >
                {title}
              </Heading>
              <Paragraph
                textAlign={
                  isPaint ? (isMobile ? 'center' : 'start') : undefined
                }
              >
                {shortDescription}
              </Paragraph>
            </Box>
          ) : (
            <Box
              align="start"
              justify="end"
              pad={
                isMobile
                  ? {
                      horizontal: 'large',
                      bottom: `${
                        buybarHeight + parseInt(theme.global.edgeSize.small)
                      }px`,
                    }
                  : 'large'
              }
              fill
            >
              <Heading
                level={1}
                style={adjustedFontStyle}
                margin={{ vertical: 'medium' }}
                textAlign="start"
              >
                {title}
              </Heading>
              <Box width={{ max: 'large' }}>
                <Paragraph>{shortDescription}</Paragraph>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

HeroProductDetail.propTypes = {
  handle: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.array,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  productType: PropTypes.string,
  variants: PropTypes.array.isRequired,
  dark: PropTypes.bool,
  backgroundColor: PropTypes.string,
  heroImage: PropTypes.object,
  pixelSwap: PropTypes.bool,
  type: PropTypes.string,
};

export default memo(HeroProductDetail);
