import { createSelector } from 'reselect';
import { map, find, compact } from '../../lib/nodash';

const selectRelatedProducts = () =>
  createSelector(
    (state) => state.product.shopifyProducts,
    (_, relatedProducts) => relatedProducts,
    (products, relatedProducts) => {
      return compact(
        map((x) => find({ handle: x }, products), relatedProducts)
      );
    }
  );

export default selectRelatedProducts;
